.dialog {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .2);
    position: absolute;
    z-index: 50;
    display: none;
    justify-content: center;
    align-items: center;
}

.dialog.dialog--open {
    display: flex;
}