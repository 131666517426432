.admin_container .admin_container__nav {
    width: 200px;
    background-color: #EDF2F7;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}

header {
    height: 56px;
}

.menu > .menu__item ul {
    display: none;
}

.menu > .menu__item--opened ul {
    display: block;
}

main {
    width: calc(100vw - 200px);
    height: calc(100vh - 56px);
    overflow-y: auto;
    padding-bottom: 50px;
}