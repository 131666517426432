.auth-container .auth-container__bg {
    background: linear-gradient(to bottom, rgba(0, 0, 0, .7), transparent), url('../../assets/img/login_background.jpg');
    background-size: cover;
    background-position: center;
}

.auth-quote {
    position: relative;
}

.auth-quote::before {
    content: url("data:image/svg+xml,%3Csvg width='42' height='35' viewBox='0 0 42 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M41.8158 5.46638C35.5527 8.50327 32.4213 12.0716 32.4213 16.1714C35.0908 16.4751 37.2982 17.5506 39.0436 19.398C40.7891 21.2455 41.6618 23.3839 41.6618 25.8134C41.6618 28.3948 40.8148 30.5712 39.1207 32.3427C37.4265 34.1143 35.2961 35 32.7293 35C29.8544 35 27.3646 33.8485 25.2598 31.5456C23.155 29.2426 22.1026 26.4461 22.1026 23.1562C22.1026 13.2863 27.6983 5.56763 38.8896 0L41.8158 5.46638ZM19.7132 5.46638C13.3988 8.50327 10.2416 12.0716 10.2416 16.1714C12.9624 16.4751 15.1956 17.5506 16.941 19.398C18.6864 21.2455 19.5592 23.3839 19.5592 25.8134C19.5592 28.3948 18.6993 30.5712 16.9795 32.3427C15.2597 34.1143 13.1165 35 10.5496 35C7.67477 35 5.19782 33.8485 3.11869 31.5456C1.03955 29.2426 0 26.4461 0 23.1562C0 13.2863 5.56994 5.56763 16.71 0L19.7132 5.46638Z' fill='white'/%3E%3C/svg%3E%0A");;
    position: absolute;
    opacity: .3;
    top: -8px;
    left: -18px;
}

